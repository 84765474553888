/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Action, Getter, Mutation, State, namespace } from 'vuex-class'
import { ModelOnWork } from '@/store/modelOnWork/types';
import { getLocalStorageService } from '@/services/storage.service'
import $ from "jquery";
import * as Flashphoner from '@flashphoner/websdk';
const AdapterJS = require('adapterjs');
import axios from 'axios';

const customer = namespace('customer');
const model = namespace('modelOnWork');
//const modelAccount = namespace('modelAccount');

export default class ModelAtWorkInModeWebcamPage extends Vue {
	@model.State('modelOnWork') modelOnWork: ModelOnWork | undefined;
    @customer.Action('fetchUserData') fetchUserData: any;
    @customer.Getter('getUserData') userData: any;
    @model.Action('startStream') startStream: any;
    @model.Getter('getStreamID') getStreamID: any;
    @model.Action('streamProgress') streamProgress: any;
    @model.Action('stopStream') stopStream: any;

    //Чат
    @model.Action('getChatMessages') getChatMessages: any;
    @model.Action('sendChatMessages') sendChatMessages: any;
    @model.Getter('chatMessages') chatMessages: any;
    @model.Mutation('setChatMessages') setChatMessages: any;

    //Экшены
    @model.Action('getActions') GetActions: any;
    @model.Action('actionStart') actionStart: any;
    @model.Action('actionCancel') actionCancel: any;
    @model.Action('actionComplete') actionComplete: any;
    @model.Getter('modelActions') modelActions: any;
    @model.Mutation('setModelActions') setModelActions: any;

	privateRequest = true;


	streamModelState : any = {
        dateToday :(new Date()).toLocaleDateString('en-GB'),
        ppsdTotal : 0,
        actionsHistoty : []
    };

    //Чат
    myMessage: any = "";
    chatAllMessages : any = [];

	//Действия для модели
	modelActionActive : any = null;
    // modelActions мутатор

    ActionStart(index : number, idAction : number)
    {
        if (this.modelActionActive)
            return;

        this.modelActionActive = this.modelActions[index];
        this.modelActionActive.visualTime = this.modelActions[index].time;
        this.modelActionActive.status = "active";

        this.state.mobileStreamActionsMenu = false;

        this.actionStart(idAction);
    }

    ActionCancel(idAction : number)
    {
        console.log("Try remove tag #"+idAction+"...");
        this.setModelActions(this.modelActions.filter(function( obj : any ) {
            return obj.id !== idAction;
        }));

        this.actionCancel(idAction);
    }

    ActionInWork()
    {
        let _this = this;

        if (!this.modelActionActive)
            return;

        if (this.modelActionActive.visualTime > 0)
        {
            this.modelActionActive.visualTime--;
        }
        else
        {
            this.actionComplete(this.modelActionActive.id);

            this.modelActions = this.modelActions.filter(function( obj : any ) {
                return obj.id !== _this.modelActionActive.id;
            });

            var m = new Date(); //02.10.2021, 21:13
            var dateString =  m.getUTCDate()  +"."+  (m.getUTCMonth()+1) +"."+ m.getUTCFullYear() + ", " + m.getUTCHours() + ":" + m.getUTCMinutes();
            this.streamModelState.actionsHistoty.push({
                date : dateString,
                name :  this.modelActionActive.name,
                price : this.modelActionActive.price
            });

            this.streamModelState.ppsdTotal += this.modelActionActive.price;

            this.modelActionActive = null;
        }
    }

    //Стрим
    SESSION_STATUS = Flashphoner.constants.SESSION_STATUS;
    STREAM_STATUS = Flashphoner.constants.STREAM_STATUS;
    Browser = Flashphoner.Browser;
    state : any = {
        stream: null,
        idStream : "",
        session: null,
        serverUrl: 'wss://18.157.148.99:8443',

        streamWork: false,
        fullScreen: false,
        showChat: true,
        sessionStatus: '',
        localVideo: null,
        remoteVideo: null,
        publishStream: null,
        sound: true,
        mobileStreamActionsMenu : false
    };



	StartStream()
	{
	    var _this = this;

	    console.log('Create stream ID...');
	    this.startStream().then(function () {

            Flashphoner.createSession({urlServer: _this.state.serverUrl}).on(_this.SESSION_STATUS.ESTABLISHED, (session: any) => {
                console.log("SESSION_STATUS.ESTABLISHED");

                //Получаем сессию
                _this.state.session = session;

                _this.state.idStream = _this.getStreamID;
                if (!_this.state.idStream)  {
                    console.log("ERROR: getStreamID() is not return ID");
                    return;
                }
                console.log('Stream ID: ', _this.state.idStream);


                if (_this.state.session && _this.state.localVideo) {
                    console.log("Ready for start stream...");

                    _this.state.session.createStream({
                        name: _this.state.idStream,
                        display: _this.state.localVideo,
                        cacheLocalResources: false,
                        receiveVideo: false,
                        receiveAudio: false
                    }).on(_this.STREAM_STATUS.PUBLISHING, (stream: any) => {
                        console.log("STREAM_STATUS.PUBLISHING");
                        _this.state.streamWork = true;
                        _this.state.stream = stream;

                        setTimeout(_this.UpdatePreview, 1500);

                        _this.GetChatMessages();
                        _this.GetActions();

                    }).on(_this.STREAM_STATUS.UNPUBLISHED, () => {
                        console.log("STREAM_STATUS.UNPUBLISHED");
                        _this.state.streamWork = false;

                    }).on(_this.STREAM_STATUS.FAILED, () => {
                        console.log("STREAM_STATUS.FAILED");
                        _this.state.streamWork = false;

                    }).publish();


                }
                else if (!_this.state.session)
                {
                    console.log("Session not found...", _this.state.session);
                }
                else if (!_this.state.localVideo)
                {
                    console.log("Local viewport not found...", _this.state.localVideo);
                }

            }).on(_this.SESSION_STATUS.DISCONNECTED, () => {
                console.log("SESSION_STATUS.DISCONNECTED");
                _this.state.streamWork = false;

            }).on(_this.SESSION_STATUS.FAILED, () => {
                console.log("SESSION_STATUS.FAILED");
                _this.state.streamWork = false;

            });
        });
	}

	StopStream() {
        if (this.state.stream) {
            this.state.stream.stop();
            this.state.stream = null;
            this.state.session.disconnect();
        }

        this.state.streamWork = false;

        this.stopStream(this.state.idStream);

        this.state.idStream = "";

        this.chatAllMessages = [];
        this.modelActionActive = null;
        this.setModelActions([]);
    }

    SwitchFullScreen()
    {
        this.state.fullScreen = !this.state.fullScreen;

        if (!this.state.fullScreen)
        {
            this.state.showChat = true;
            $('body').removeAttr('style');

            this.$nextTick(() => this.ScrollChatToDown());
        }
        else
        {
            if (<any>$(window).width() < 600)
                this.state.showChat = false;

            $('body').css('overflow', 'hidden');
        }
    }

    SwitchChat()
    {
        this.state.showChat = !this.state.showChat;
    }

	requestToPrivate() {
		this.$router.push({ name: 'model-at-work-in-mode-webcam-in-private' }).then(r => r);
	}

    Pad(d : any) {
        return (d < 10) ? '0' + d.toString() : d.toString();
    }

    SendMessage(e : any)
    {
        if (!this.state.streamWork || !this.myMessage)
            return;

        var _this = this;

        if (e === null || (e.keyCode == 13 || e.key == "Enter"))
        {
            this.sendChatMessages({ get : { streamId : this.state.idStream }, post : { text : this.myMessage } }).then(function () {
                _this.GetChatMessages();
            });

            this.$nextTick(() => {
                let chat : any = document.getElementById("js-chat-messages");
                chat.scrollTop = chat.scrollHeight + 1000;
            });

            this.myMessage = "";
        }
    }

    ScrollChatToDown()
    {
        let chat : any = document.getElementById("js-chat-messages");
        chat.scrollTop = chat.scrollHeight + 1000;
    }

    UpdatePreview()
    {
        this.state.stream.snapshot();
    }

    GetChatMessages()
    {
        if (!this.state.streamWork)
            return;

        var _this = this;

        var lastMessageID = 0;
        if (this.chatAllMessages.length)
            lastMessageID = this.chatAllMessages[this.chatAllMessages.length-1].id;

        this.getChatMessages({ idStream : this.state.idStream, messageId : lastMessageID }).then(function() {
            let i = 0;
            _this.chatMessages.forEach(function(data : any)
            {
                setTimeout(function () {
                    //Не вставляем если дубликат (рассинхронизация получения-отправки)
                    for (let msg of _this.chatAllMessages){
                        if (msg.id == data.id)
                            return;
                    }

                    _this.chatAllMessages.push(data);
                    _this.$nextTick(() => _this.ScrollChatToDown());
                }, 200 * i);

                i++;
            });
        });

        _this.GetActions(this.state.idStream).then(function (response : any) {

        })
    }

    StreamProgress()
    {
        if (this.state.streamWork && this.state.idStream)
        {
            this.UpdatePreview();

            let data = this.state.stream.getInfo();
            if (data)
            {
                let snapshop = "data:image/png;base64,"+this.state.stream.getInfo();
                //console.log("SCREENSHOT", snapshop);

                this.streamProgress({ get: { streamId : this.state.idStream }, post : { streamPreview: snapshop }});
            }
        }
    }

	mounted() {
        this.fetchUserData().then(() => {

        });

        //Скроллим чат вниз
        setTimeout(this.ScrollChatToDown, 500);

        //Раз в секунду (таймер для экшенов модели)
        setInterval(this.ActionInWork, 1000);

        //Обновляем что стрим идёт
        setInterval(this.StreamProgress, 10000);

        setInterval(this.GetChatMessages, 3000);

        try {
            //Фикс для адаптера (не знаю почему либа не видит сама)
            const AdapterJS = require('adapterjs');
            (<any>window).AdapterJS  = AdapterJS;

            //Инициализация
            Flashphoner.init({});

            this.state.localVideo = document.getElementById('js-video');
        }
        catch(e) {
            console.log(e);
        }
	}
}
